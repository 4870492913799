<template>
  <div>
    <div>
      <b-card>
        <div class="row mb-3">
          <div class="col-12 d-flex">
            <span class="h2 mr-auto text-secondary">
              Nouvelle organisation</span
            >

            <div>
              <modal-actions @close="$router.go(-1)" />
            </div>
          </div>
        </div>
        <div class="shdow">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h3 text-secondary">Informations générales</span>
            <div>
              <b-button
                type="submit"
                @click="addItem"
                pill
                :disabled="submitingForm"
              >
                <b-spinner
                  small
                  v-if="submitingForm"
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
            </div>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />

          <div class="row my-4">
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                class="font-weight-bold"
                label="Entité juridique :"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="({ value }) => value"
                    :options="entitesList"
                    placeholder="Entité juridique"
                    v-model="entiteSelected"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.entiteSelected.$error && $v.entiteSelected.$dirty,
                    }"
                    @input="parent = null"
                  >
                    ></v-select
                  >

                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.entiteSelected.$dirty"
                  >
                    {{ !$v.entiteSelected.required ? "Champ obligatoire" : "" }}
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Libellé :"
              >
                <b-form-input
                  placeholder="Libellé"
                  v-model="libelleSelected"
                  type="text"
                  :class="{
                    'is-invalid':
                      $v.libelleSelected.$error && $v.libelleSelected.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.libelleSelected.$dirty"
                >
                  {{
                    !$v.libelleSelected.required
                      ? "Champ obligatoire"
                      : !$v.libelleSelected.minLength
                      ? `Le champ doit contenir au moins ${$v.libelleSelected.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Code organisation :"
                class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Code organisation"
                  v-model="code"
                  type="text"
                  :class="{
                    'is-invalid': $v.code.$error && $v.code.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.code.$dirty"
                >
                  {{ !$v.code.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Responsable :"
                class="font-weight-bold"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    :options="responsables"
                    placeholder="Responsable"
                    v-model="responsable"
                    type="text"
                    style="padding-right: 0 !important"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <!-- ERROR MESSAGES -->
                  <!-- <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.responsable.$dirty"
                  >
                    {{ !$v.responsable.required ? "champ obligatoire" : "" }}
                  </span> -->
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                class="font-weight-bold"
                label="Organisation de rattachement :"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="({ value }) => value"
                    v-model="parent"
                    :options="organisationsList"
                    placeholder="Organisation de rattachement"
                  ></v-select>
                </div>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Niveau :"
              >
                <b-form-input
                  placeholder="Niveau"
                  v-model="niveau"
                  disabled
                  type="number"
                  :class="{
                    'is-invalid': $v.niveau.$error && $v.niveau.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.niveau.$dirty"
                >
                  {{ !$v.niveau.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Type :"
              >
                <div class="font-weight-normal">
                  <v-select
                    :options="type"
                    placeholder="Type"
                    v-model="typeselected"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.typeselected.$error && $v.typeselected.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.typeselected.$dirty"
                  >
                    {{ !$v.typeselected.required ? "champ obligatoire" : "" }}
                  </span>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="row" style="margin-top: -15px">
          <div class="col-12 px-0 p-4">
            <b-tabs
              :value="0"
              @changed="activeTab = $store.state.tabs.activeTab"
              @activate-tab="
                (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab title="Équipes">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="d-flex my-2">
                      <div>
                        <span class="h4 text-secondary mr-auto my-auto"
                          >Liste des équipes</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-md-6
                      d-flex
                      justify-content-end
                      align-btn-table-2
                    "
                  >
                    <button
                      type="button"
                      class="
                        btn
                        h1
                        mb-2
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center
                      "
                      style="
                        background-color: #007bffff !important;
                        border-color: #007bffff !important;
                      "
                      disabled
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Créer une équipe
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                  <div class="col-12">
                    <TeamTable :items="[]"></TeamTable>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Collaborateurs">
                <div class="row">
                  <div class="col-12 col-sm-3 my-3">
                    <span class="h4 text-secondary mr-auto my-auto"
                      >Liste des collaborateurs</span
                    >
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div>
                      <b-table
                        responsive
                        :items="[]"
                        :fields="tableFields"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-desc.sync="sortDesc"
                        show-empty
                        bordered
                        striped
                      >
                        <template #empty>
                          <div class="text-center text-gray">Table vide</div>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="table-list"
                        align="right"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
//import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import TeamTable from "../components/TeamTable";
import { USER_URL, ENTITE_URL } from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    TeamTable,
  },
  data: () => ({
    editMode: false,
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    tableFields: [
      { key: "responsable", label: "Responsable", sortable: true },
      { key: "function", label: "Fonction", sortable: true },
      { key: "hiearchie", label: "Niveau hiérachique ", sortable: true },
    ],
    entiteSelected: "",
    code: "",
    sousOrganisationselected: [],
    parent: null,
    typeselected: "",
    niveau: 1,
    libelleSelected: "",
    responsable: "",
    selectedResponsable: null,
    submitingForm: false,
  }),
  validations: {
    entiteSelected: {
      required,
    },
    libelleSelected: {
      required,
      minLength: minLength(5),
    },
    typeselected: {
      required,
    },
    niveau: {
      required,
    },
    code: {
      required,
    },
    // responsable: {
    //   required,
    // },
  },
  methods: {
    addItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          type: this.typeselected,
          // sousOrganisations: this.sousOrganisationselected.map((org) => org.value),
          code: this.code,
          libelle: this.libelleSelected,
          entite: `/api/${ENTITE_URL}/${this.entiteSelected}`,
          niveau: parseInt(this.niveau),
          responsable: this.responsable ? this.responsable : null,
          parent: this.parent,
        };
        this.$store
          .dispatch("organisation/createOrganisation", item)
          .then(() => {
            // this.$router.push({name: 'organisations'})
            Swal.fire({
              title: "L'organisation est bien créée !",
              type: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.$router.push({
                  name: "show-organisation",
                  params: {
                    slug: this.ORGANISATION.slug || "abcd",
                    id: this.ORGANISATION.id,
                    fromCreation: true,
                  },
                }); //redirect to the new task
              }
            });
          });
      }
    },
  },

  mounted() {},
  watch: {
    parent() {
      if (this.parent) {
        this.niveau =
          this.ORGANISATIONS.find((orga) => orga["@id"] === this.parent)
            .niveau + 1;
      } else {
        this.niveau = 1;
      }
    },
  },

  created() {
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchOrganisationTypes");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("users/fetchAllResponsables");
  },

  computed: {
    ...mapGetters("type", ["ORG_TYPES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATION"]),

    rows() {
      return 0;
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisationsList() {
      return this.entiteSelected
        ? this.ORGANISATIONS.filter((organisation) =>
            organisation.entite
              ? parseInt(organisation.entite.id) ===
                parseInt(this.entiteSelected)
              : false
          )
            .filter((organisation) => organisation.niveau === 1)
            .map((org) => ({
              value: org["@id"],
              text: org.libelle,
            }))
        : [];
    },
    type() {
      return this.ORG_TYPES;
    },
    entitesList() {
      return this.ENTITIES.map((entity) => ({
        value: `${entity.id}`,
        text: entity.libelle,
      }));
    },
  },
};
</script>

<style></style>
